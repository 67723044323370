<template>
  <section id="CompraRealizada" class="compraRealizada">
    <v-container
      v-if="documentoCompra"
      class="mx-auto pa-4"
      style="width: fit-content"
    >
      <div class="compraRealizada__row text-center mt-4 pa-10">
        <h1 class="mb-3">Gracias por tu compra</h1>
        <h3>
          Te hemos enviado un mail a tu dirección de electrónico electrónico
          confirmando tu compra
        </h3>
      </div>
      <!-- <h2 class="pb-7 d-md-none">Detalle de tu ticket</h2>
      <v-row class="compraRealizada__row" no-gutters>
        <v-col cols="12" md="5">
          <div ref="ticket" class="compraRealizada__ticket mx-auto">
            <TIcketCompraRealizada
              :documentoCompra="documentoCompra"
              class="pb-4"
            >
              <v-divider class="pb-3" />
              <h3 class="compraRealizada__ticket__itemTitle">
                Fecha de compra
              </h3>
              <p class="compraRealizada__ticket__itemContent">
                {{
                  new Date(documentoCompra.date).toLocaleDateString("es-ES", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }}
              </p>
              <h3 class="compraRealizada__ticket__itemTitle">Monto total</h3>
              <p class="compraRealizada__ticket__itemContent">
                ${{ precioEnMiles(documentoCompra.details.total) }}
              </p>
              <h3 class="compraRealizada__ticket__itemTitle">
                Código de venta
              </h3>
              <p class="compraRealizada__ticket__itemContent">
                {{ documentoCompra.id }}
              </p>
              <h3 class="compraRealizada__ticket__itemTitle">Comprado por:</h3>
              <p class="compraRealizada__ticket__itemContent">
                {{ documentoCompra.userData.displayName }}
              </p>
              <CodigoQR
                :valor="urlCodigoQR"
                class="compraRealizada__ticket__codigo d-flex mx-auto"
              />

              <div @click="generatePdf(documentoCompra)">
                <Boton texto="Descargar Boleto" width="100%" height="47px" />
              </div>
              <div class="d-none d-md-block mt-3">
                <Boton
                  texto="Volver a inicio"
                  width="100%"
                  height="47px"
                  color="#939393"
                />
              </div>
            </TIcketCompraRealizada>

            <v-dialog v-model="dialog" persistent v-if="mediumViewport">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <Boton
                    class="d-md-none"
                    texto="Finalizar"
                    color="red"
                    width="100%"
                    height="47px"
                  />
                </span>
              </template>
              <v-card> <CalificarExperiencia /> </v-card>
            </v-dialog>
          </div>
        </v-col>
        <v-col cols="7" class="compraRealizada__comentarios d-none d-md-block">
          <div class="text-center">
            <h1>Gracias por tu compra</h1>
            <h2>
              Puedes descargar el detalle de tu compra, igualmente te enviaremos
              un correo con el detalle.
            </h2>
            <CalificarExperiencia />
          </div>
        </v-col>
      </v-row> -->
    </v-container>
    <v-container v-else-if="error" class="mx-auto text-center pt-15">
      <h1>
        NO HAY DOCUMENTOS DE COMPRA QUE COINCIDAN CON LOS DATOS INGRESADOS
      </h1>
    </v-container>
  </section>
</template>

<script>
import CodigoQR from "@/components/Cards/Subcomponentes/CodigoQR";
import TIcketCompraRealizada from "@/components/Cards/TIcketCompraRealizada";
import Boton from "@/components/Botones/Boton";
import CalificarExperiencia from "@/components/Feedback/CalificarExperiencia";
import { mapState } from "vuex";

import utils from "@/helpers/utils.js";
const { generatePdf } = utils;

import firebase from "firebase";
export default {
  name: "CompraRealizada",
  props: ["id"],
  components: {
    TIcketCompraRealizada,
    CodigoQR,
    Boton,
    CalificarExperiencia,
  },
  data() {
    return {
      dialog: false,
      total: 0,
      documentoCompra: null,
      error: false,
    };
  },
  computed: {
    ...mapState("Ventas", ["ventaActual", "datosVenta"]),
    mediumViewport() {
      return this.$vuetify.breakpoint.width < 960 ? true : false;
    },
    precioEnMiles: () => (numero) => {
      if (numero === undefined) {
        numero = 0;
      }

      return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    urlCodigoQR() {
      const urlBase = window.location.origin;
      return `${urlBase}/admin/ventas/${this.id}`;
    },
  },
  async created() {
    try {
      let data;
      await firebase
        .firestore()
        .collection("ventas")
        .doc(this.id)
        .get()
        .then((d) => {
          data = d.data();
          this.documentoCompra = data;
          this.documentoCompra.id = this.id;
        });
    } catch (error) {
      this.error = true;
    }
  },
  title() {
    return `Compra realizada - HummingBird`;
  },
  methods: {
    generatePdf,
  },
};
</script>

<style lang="scss" scoped>
#CompraRealizada,
.compraRealizada {
  padding-top: 70px;
  min-height: 100vh;
  background: #efefef;
  &__ticket {
    &__itemTitle {
      font-size: 15px;
    }
    &__itemContent {
      font-size: 12px;
    }
    &__codigo {
      max-width: 80%;
    }
  }
}

@media (min-width: 960px) {
  #CompraRealizada,
  .compraRealizada {
    padding-top: 100px;
    padding-bottom: 100px;
    &__row {
      background: $main-white;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
      padding: 35px 10px;
    }
    &__ticket {
      max-width: 310px;
    }
    &__comentarios {
      h1 {
        color: $main-purple;
        font-size: 36px;
      }
      h2 {
        font-weight: 400;
      }
    }
  }
}
</style>

<template>
  <div
    class="calificarExperiencia d-flex flex-column justify-center mt-5 col-md-6 mx-auto"
  >
    <h2 style="color:#574dad" class="text-center mb-5">
      Gracias por volar con Hummingbird
    </h2>
    <h4 class="text-center">¿Cómo calificas tu experiencia?</h4>
    <div class="d-flex justify-center">
      <v-rating
        color="warning"
        background-color="#C4C4C4"
        v-model="rating"
        :size="ratingSize"
        half-increments
        hover
      />
    </div>
    <v-textarea
      outlined
      dense
      rows="3"
      placeholder="Comparte tu opinión con otros usuarios"
      no-resize
      :color="colors.mainPurple"
      v-model="comentario"
    >
    </v-textarea>
    <div @click="enviarComentario" class="text-center">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="deep-purple"
      ></v-progress-circular>
      <Boton
        v-else
        class="calificarExperiencia__btn"
        texto="Enviar calificación"
        height="47px"
        @click="enviarComentario"
      />
    </div>
  </div>
</template>

<script>
import Boton from "@/components/Botones/Boton";
import { mapActions, mapState } from "vuex";
import firebase from "firebase";

export default {
  name: "CalificarExperiencia",
  components: {
    Boton,
  },
  data() {
    return {
      rating: 0,
      comentario: "",
      loading: false,
    };
  },
  computed: {
    ...mapState(["colors"]),
    ratingSize() {
      if (this.$vuetify.breakpoint.width >= 960) {
        return 56;
      } else {
        return 30;
      }
    },
  },
  methods: {
    ...mapActions("Comentarios", ["addCommentary"]),
    async enviarComentario() {
      const {
        params: { idExperiencia },
        query: { usuario, destino },
      } = this.$route;
      const { comentario, rating } = this;
      try {
        this.loading = true;
        if (!comentario)
          throw "Debe escribir un comentairo o marcar una calificación";
        const date = new Date()
        await firebase
          .firestore()
          .collection("experiencias")
          .doc(idExperiencia)
          .update({
            comentarios: firebase.firestore.FieldValue.arrayUnion({
              comentario,
              rating,
              usuario,
              date,
            }),
          });
        await firebase
          .firestore()
          .collection("comentarios")
          .add({
            comentario,
            idExperiencia,
            usuario,
            rating,
            destino,
            date: firebase.firestore.FieldValue.serverTimestamp(),
          });

        this.$toast.open({
          message: "Gracias! Tu evaluación ha sido registrada con éxito",
          position: "top",
          duration: 3000,
        });
        setTimeout(() => this.$router.push("/"), 3000);
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: "Algo salió mal :( ..." + error,
          position: "top",
          duration: 3000,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.calificarExperiencia {
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  padding: 25px;
  h1 {
    font-size: 15px;
  }
  &__btn {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 960px) {
  .calificarExperiencia {
    padding: 36px 88px;
    h1 {
      font-size: 25px;
    }
  }
}
</style>

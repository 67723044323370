<template>
  <vue-qrcode class="qrcode" :value="valor" />
</template>

<script>
import VueQrcode from "vue-qrcode";
import { mapState } from "vuex";
export default {
  name: "CodigoQR",
  components: {
    VueQrcode,
  },
  props: {
    valor: {
      default: null,
    },
  },
  computed: {
    ...mapState("Ventas", ["ventaActual"]),
    ...mapState("Auth", ["usuario"]),
    ...mapState(["colors"]),
  },
};
</script>

<style lang="scss" scoped>
.qrcode {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 100%;
}
</style>